import React from 'react';
import Icon from '@mdi/react';
import { 
    mdiCalendarCheck, 
    mdiSpeedometer, 
    mdiAirFilter, 
    mdiShieldCheck 
} from '@mdi/js';
import Header from "../layout/header";
import Footer from "../layout/footer";
import PageTitle from "../elements/page-title";

// Images
import bakimImg from "../../images/services/bakim1.png";
import genelkontrolImg from "../../images/services/bakim2.png";

const Bakim = () => {
    return (
        <>
            <Header />
            <PageTitle motherMenu="Hizmetlerimiz" activeMenu="LPG Bakım Servisi" />
            
            <section className="content-inner service-page">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-head style-1 text-center mb-4">
                                <h2 className="title">LPG Sistem Bakımı</h2>
                                <div className="sub-title">Aracınızın Performansı İçin Düzenli Bakım</div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row align-items-center mb-5">
                        <div className="col-lg-6 mb-3">
                            <img src={bakimImg} alt="LPG Bakım" className="rounded w-100" />
                        </div>
                        <div className="col-lg-6 mb-3">
                            <div className="info-box">
                                <h3>Neden Düzenli Bakım Önemli?</h3>
                                <p>LPG sisteminin düzenli bakımı, aracınızın performansını ve güvenliğini doğrudan etkiler. Bakım hizmetlerimiz:</p>
                                <ul className="list-check primary">
                                    <li>Filtre değişimi ve temizliği</li>
                                    <li>Sistem kontrolü ve ayarları</li>
                                    <li>Kaçak testi ve güvenlik kontrolü</li>
                                    <li>Performans optimizasyonu</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-3 col-md-6 mb-4">
                            <div className="feature-container bg-light p-4 rounded text-center">
                                <div className="icon-lg mb-3">
                                    <Icon path={mdiCalendarCheck} size={1.5} />
                                </div>
                                <h4>Periyodik Bakım</h4>
                                <p>Her 10.000 km'de bir önerilen genel sistem kontrolü</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-4">
                            <div className="feature-container bg-light p-4 rounded text-center">
                                <div className="icon-lg mb-3">
                                    <Icon path={mdiSpeedometer} size={1.5} />
                                </div>
                                <h4>Performans Kontrolü</h4>
                                <p>Yakıt tüketimi ve motor performans optimizasyonu</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-4">
                            <div className="feature-container bg-light p-4 rounded text-center">
                                <div className="icon-lg mb-3">
                                    <Icon path={mdiAirFilter} size={1.5} />
                                </div>
                                <h4>Filtre Bakımı</h4>
                                <p>Düzenli filtre değişimi ve sistem temizliği</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-4">
                            <div className="feature-container bg-light p-4 rounded text-center">
                                <div className="icon-lg mb-3">
                                    <Icon path={mdiShieldCheck} size={1.5} />
                                </div>
                                <h4>Güvenlik Kontrolü</h4>
                                <p>Detaylı güvenlik testi ve sertifikasyon</p>
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center mt-5">
                        <div className="col-lg-6 mb-3">
                            <div className="info-box">
                                <h3>Bakım Sürecimiz</h3>
                                <p>Profesyonel ekibimiz, bakım sürecini detaylı ve titiz bir şekilde gerçekleştirir:</p>
                                <ul className="list-check primary">
                                    <li>Genel sistem kontrolü ve arıza tespiti</li>
                                    <li>Filtre ve parça değişimi</li>
                                    <li>Sistem temizliği ve bakımı</li>
                                    <li>Test ve kalibrasyon işlemleri</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-3">
                            <img src={genelkontrolImg} alt="Genel Kontrol" className="rounded w-100" />
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
}

export default Bakim; 