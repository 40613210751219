import React, {useState} from 'react';

// Layout
//import Header from "../layout/header";
import Footer from "../layout/footer";

// Elements
import Header from "../layout/header";
import AboutUsOneSection from "../elements/about-us-1";
import ServicesSection from "../elements/services-1";
import ChooseUsSection from "../elements/choose-us";
import BlogSection from '../elements/blog-section';
import SavingsBot from '../elements/savingsBot';
import ImageBig from '../image-big';
import IframeModal from '../../components/IframeModal';
const Index=()=>{	
	
	const [showModal, setShowModal] = useState(true);
	const hasRegistered = localStorage.getItem('hasRegistered') === 'true';
		
	const handleModalClose = () => {
		setShowModal(false);
	};

	const handleRegistrationSuccess = () => {
		localStorage.setItem('hasRegistered', 'true');
		setShowModal(false);
	};
		return (
			<>
				{showModal && !hasRegistered && (
					<IframeModal 
						url="https://bakim.evrenotogaz.com/" 
						onClose={handleModalClose} 
						className="iframe-modal-overlay"
						onSuccess={handleRegistrationSuccess}
					/>
				)}
				<Header />
				<ImageBig />
				<SavingsBot />
				<AboutUsOneSection />
				<ServicesSection />
				<ChooseUsSection />
				<BlogSection />
				<Footer />
				
			</>
		);
}

export default Index;