import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Sticky from "react-stickynode";

import logo from "../../images/evren-side-laci.png";
import logoWhite from "../../images/evren-side.png";

const Header = () => {
  useEffect(() => {
    // Mobile Menu Open
    var menuIcon = document.querySelector(".menuicon");
    var menuLinks = document.querySelector(".menu-links");
    var menuClose = document.getElementById("menuClose");

    menuIcon?.addEventListener("click", function () {
      menuLinks?.classList.add("show");
    });

    menuClose?.addEventListener("click", function () {
      menuLinks?.classList.remove("show");
    });

    // Mobile Submenu Open Close Function
    var navMenu = [].slice.call(
      document.querySelectorAll(".menu-links > ul > li")
    );

    function handleSubmenu(e) {
      if (window.innerWidth < 991) {
        e.preventDefault();
        const current = e.currentTarget;
        const hasClass = current.classList.contains("open");

        // Close all other open submenus
        navMenu.forEach((el) => {
          if (el !== current) {
            el.classList.remove("open");
          }
        });

        // Toggle current submenu
        if (hasClass) {
          current.classList.remove("open");
        } else {
          current.classList.add("open");
        }
      }
    }

    // Add click event to menu items with submenu
    navMenu.forEach((item) => {
      const hasSubmenu = item.querySelector(".mega-menu");
      if (hasSubmenu) {
        const link = item.querySelector("a");
        link?.addEventListener("click", handleSubmenu);
      }
    });

    // Cleanup
    return () => {
      navMenu.forEach((item) => {
        const hasSubmenu = item.querySelector(".mega-menu");
        if (hasSubmenu) {
          const link = item.querySelector("a");
          link?.removeEventListener("click", handleSubmenu);
        }
      });
    };
  }, []);

  return (
    <>
      <header className="header header-transparent rs-nav">
        <Sticky enabled={true} className="sticky-header navbar-expand-lg">
          <div className="menu-bar clearfix">
            <div className="container clearfix">
              <div className="menu-logo">
                <Link to="/" className="main-logo">
                  <img src={logoWhite} alt="" />
                </Link>
                <Link to="/" className="sticky-logo">
                  <img src={logo} alt="" />
                </Link>
              </div>
              <button
                className="navbar-toggler collapsed menuicon justify-content-end"
                type="button"
                data-toggle="collapse"
                data-target="#menuDropdown"
                aria-controls="menuDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span></span>
                <span></span>
              </button>

              <div
                className="menu-links navbar-collapse collapse justify-content-center"
                id="menuDropdown"
              >
                <div className="menu-logo">
                  <Link to="/">
                    <img src={logoWhite} alt="" />
                  </Link>
                </div>

                <ul className="nav navbar-nav">
                  {/* show Anasayfa when page is not '/' */}
                  {window.location.pathname !== "/" && (
                    <li>
                      <Link to="/">
                        <i className="fas fa-home"></i> Ana Sayfa
                      </Link>
                    </li>
                  )}
                  {window.location.pathname !== "/montaj" && (
                    <li>
                      <Link to="/montaj">
                        <i className="fas fa-wrench"></i> LPG Montaj
                      </Link>
                    </li>
                  )}{" "}
                  {window.location.pathname !== "/tank-degisimi" && (
                    <li>
                      <Link to="/tank-degisimi">
                        <i className="fas fa-gas-pump"></i> Tank Değişimi
                      </Link>
                    </li>
                  )}
                  {window.location.pathname !== "/bakim" && (
                    <li>
                      <Link to="/bakim">
                        <i className="fas fa-tools"></i> LPG Bakım
                      </Link>
                    </li>
                  )}
                  {/* <li>
                    <Link to="/"><i className="fas fa-home"></i> Ana Sayfa</Link>
                  </li> */}
                  {/* <li className="has-mega-menu">
                    <Link to="#"><i className="fas fa-cogs"></i> Hizmetlerimiz</Link>
                    <ul className="mega-menu">
                      <li>
                        <Link to="/tank-degisimi"><i className="fas fa-gas-pump"></i> LPG Tank Değişimi</Link>
                      </li>
                      <li>
                        <Link to="/bakim"><i className="fas fa-tools"></i> LPG Bakım</Link>
                      </li>
                      <li>
                        <Link to="/montaj"><i className="fas fa-wrench"></i> LPG Montaj</Link>
                      </li>
                    </ul>
                  </li> */}
                  {/* <li className="has-mega-menu">
                    <Link to="#"><i className="fas fa-blog"></i> Blog</Link>
                    <ul className="mega-menu">
                      <li>
                        <Link to="/blog-neden-lpg"><i className="fas fa-question-circle"></i> Neden LPG?</Link>
                      </li>
                      <li>
                        <Link to="/blog-hala-karli"><i className="fas fa-chart-line"></i> LPG Hala Karlı mı?</Link>
                      </li>
                      <li>
                        <Link to="/blog-faaliyet-firma"><i className="fas fa-building"></i> Faaliyet Gösteren Firma</Link>
                      </li>
                      <li>
                        <Link to="/blog-otomotiv-sektorunun-gelecegi">
                          <i className="fas fa-car"></i> Otomotiv Sektörünün Geleceği
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog-lpg-tank-degisimi"><i className="fas fa-gas-pump"></i> LPG Tank Değişimi</Link>
                      </li>
                      <li>
                        <Link to="/blog-lpg-vs-elektrikli-araclar">
                          <i className="fas fa-charging-station"></i> LPG vs Elektrikli Araçlar
                        </Link>
                      </li>
                    </ul>
                  </li> */}
                </ul>

                <ul className="social-media">
                  <li>
                    <a
                      target="_blank"
                      href="https://www.facebook.com/"
                      rel="noreferrer"
                      className="btn btn-primary"
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.google.com/"
                      rel="noreferrer"
                      className="btn btn-primary"
                    >
                      <i className="fa fa-google-plus"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/"
                      rel="noreferrer"
                      className="btn btn-primary"
                    >
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://twitter.com/"
                      rel="noreferrer"
                      className="btn btn-primary"
                    >
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                </ul>
                <div className="menu-close" id="menuClose">
                  <i className="ti-close"></i>
                </div>
              </div>
            </div>
          </div>
        </Sticky>
      </header>
    </>
  );
};

export default Header;
