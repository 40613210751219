import React from 'react';
import Icon from '@mdi/react';
import { 
    mdiShieldLock, 
    mdiAccountCogOutline, 
    mdiCertificateOutline 
} from '@mdi/js';
import Header from "../layout/header";
import Footer from "../layout/footer";
import PageTitle from "../elements/page-title";

// Images
import tankImg from "../../images/services/tank.png";
import yedekImg from "../../images/services/tanksimit.png";

const TankDegisimi = () => {
    return (
        <>
            <Header />
            <PageTitle motherMenu="Hizmetlerimiz" activeMenu="LPG Tank Değişimi" />
            
            <section className="content-inner service-page">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-head style-1 text-center mb-4">
                                <h2 className="title">LPG Tank Değişimi</h2>
                                <div className="sub-title">Güvenli ve Profesyonel Tank Değişim Hizmeti</div>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center mt-5">
                        <div className="col-lg-6 mb-3">
                            <img src={yedekImg} alt="Yedek Tank" className="rounded w-100" />
                        </div>
                        <div className="col-lg-6 mb-3">
                            <div className="info-box">
                                <h3>Tank Değişim Sürecimiz</h3>
                                <p>Profesyonel ekibimiz, tank değişim sürecini güvenli ve hızlı bir şekilde gerçekleştirir:</p>
                                <ul className="list-check primary">
                                    <li>Eski tankın güvenli şekilde sökülmesi</li>
                                    <li>Yeni tankın montajı ve sızdırmazlık testleri</li>
                                    <li>Sistem kontrolü ve kalibrasyon</li>
                                    <li>Gerekli belgelerin hazırlanması</li>
                                </ul>
                            </div>
                        </div>
                    </div>

          

                    <div className="row">
                        <div className="col-lg-4 col-md-6 mb-4">
                            <div className="feature-container bg-light p-4 rounded text-center">
                                <div className="icon-lg mb-3">
                                    <Icon path={mdiShieldLock} size={1.5} />
                                </div>
                                <h4>Güvenlik Önceliğimiz</h4>
                                <p>En yüksek güvenlik standartlarında tank değişimi yapıyoruz.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-4">
                            <div className="feature-container bg-light p-4 rounded text-center">
                                <div className="icon-lg mb-3">
                                    <Icon path={mdiAccountCogOutline} size={1.5} />
                                </div>
                                <h4>Uzman Ekip</h4>
                                <p>Deneyimli teknisyenlerimizle profesyonel hizmet sunuyoruz.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-4">
                            <div className="feature-container bg-light p-4 rounded text-center">
                                <div className="icon-lg mb-3">
                                    <Icon path={mdiCertificateOutline} size={1.5} />
                                </div>
                                <h4>Garantili Hizmet</h4>
                                <p>Tüm işlemlerimiz garanti kapsamında ve belgelidir.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center mb-5">
                        <div className="col-lg-6 mb-3">
                            <div className="info-box">
                                <h3>Neden Tank Değişimi Önemlidir?</h3>
                                <p>LPG tanklarının belirli bir kullanım ömrü vardır ve güvenliğiniz için zamanında değiştirilmesi gerekir. Profesyonel ekibimiz:</p>
                                <ul className="list-check primary">
                                    <li>10 yıllık kullanım ömrü dolmuş tankların değişimi</li>
                                    <li>TSE standartlarına uygun yeni tank montajı</li>
                                    <li>Güvenlik testleri ve sertifikasyon</li>
                                    <li>Garanti kapsamında servis hizmeti</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-3">
                            <img src={tankImg} alt="Tank Değişimi" className="rounded w-100" />
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
}

export default TankDegisimi; 