import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Markup from './markup/markup';
import IframeModal from './components/IframeModal';

// Slick Carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// React Modal Video
import 'react-modal-video/css/modal-video.min.css';

// Fonts
import "./vendor/line-awesome/css/line-awesome.min.css";
import "./vendor/font-awesome/css/font-awesome.min.css";
import "./vendor/flaticon/flaticon.css";
import "./vendor/themify/themify-icons.css";

// StyleSheet
import './App.scss';

function App() {
	const [showCookieNotice, setShowCookieNotice] = useState(false);

	useEffect(() => {

		// Check cookie notice status
		const checkCookieStatus = () => {
			const cookiesAccepted = localStorage.getItem('cookiesAccepted') === 'accepted';
			const cookiesDeclined = sessionStorage.getItem('cookiesDeclined') === 'true';
			return !cookiesAccepted && !cookiesDeclined;
		};

		// Set initial states
		setShowCookieNotice(checkCookieStatus());
	}, []);



	const handleCookieAccept = () => {
		localStorage.setItem('cookiesAccepted', 'accepted');
		setShowCookieNotice(false);
	};

	const handleCookieDecline = () => {
		sessionStorage.setItem('cookiesDeclined', 'true');
		setShowCookieNotice(false);
	};

	return (
		<Router>
			<div className="page-wraper">
				<Markup />
				{showCookieNotice && (
					<div className="cookie-notice">
						<p>
							<i className="fas fa-cookie-bite"></i>
							Bu site deneyiminizi iyileştirmek için çerezler kullanır.
						</p>
						<div className="cookie-buttons">
							<button onClick={handleCookieAccept} className="accept-btn">
								Kabul Et
							</button>
							<button onClick={handleCookieDecline} className="decline-btn">
								Reddet
							</button>
						</div>
					</div>
				)}
			</div>
		</Router>
	);
}

export default App;