import React from 'react';
import Icon from '@mdi/react';
import { 
    mdiCarSearch, 
    mdiWrench, 
    mdiTuneVariant, 
    mdiCertificate 
} from '@mdi/js';
import Header from "../layout/header";
import Footer from "../layout/footer";
import PageTitle from "../elements/page-title";
import SavingsBot from "../elements/savingsBot";

// Images
import kitImg from "../../images/services/montaj.png";
import raporImg from "../../images/services/rapor.png";

const Montaj = () => {
    return (
        <>
            <Header />
            <PageTitle motherMenu="Hizmetlerimiz" activeMenu="LPG Montaj" />
            
            <section className="content-inner service-page">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-head style-1 text-center mb-4">
                                <h2 className="title">LPG Sistemi Montajı</h2>
                                <div className="sub-title">Profesyonel ve Güvenilir LPG Dönüşüm Hizmeti</div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row align-items-center mb-5">
                        <div className="col-lg-6 mb-3">
                            <div className="info-box">
                                <h3>Neden Bizi Tercih Etmelisiniz?</h3>
                                <p>20 yılı aşkın tecrübemizle, en son teknoloji LPG sistemlerini aracınıza uygun şekilde monte ediyoruz:</p>
                                <ul className="list-check primary">
                                    <li>TSE onaylı ekipman kullanımı</li>
                                    <li>Araç tipine özel sistem seçimi</li>
                                    <li>Profesyonel montaj ekibi</li>
                                    <li>2 yıl garanti</li>
                                    <li>7/24 teknik destek</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-3">
                            <img src={kitImg} alt="LPG Montaj" className="rounded w-100" />
                        </div>
                    </div>

                    <div className="row mb-5">
                        <div className="col-lg-12">
                            <div className="section-head style-1 text-center">
                                <h3>Montaj Süreci</h3>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-3 col-md-6 mb-4">
                            <div className="feature-container bg-light p-4 rounded text-center">
                                <div className="icon-lg mb-3">
                                    <Icon path={mdiCarSearch} size={1.5} />
                                </div>
                                <h4>Araç Analizi</h4>
                                <p>Aracınıza en uygun sistemin belirlenmesi</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-4">
                            <div className="feature-container bg-light p-4 rounded text-center">
                                <div className="icon-lg mb-3">
                                    <Icon path={mdiWrench} size={1.5} />
                                </div>
                                <h4>Montaj</h4>
                                <p>Profesyonel ekip ile güvenli montaj</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-4">
                            <div className="feature-container bg-light p-4 rounded text-center">
                                <div className="icon-lg mb-3">
                                    <Icon path={mdiTuneVariant} size={1.5} />
                                </div>
                                <h4>Kalibrasyon</h4>
                                <p>Sistem ayarları ve performans optimizasyonu</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-4">
                            <div className="feature-container bg-light p-4 rounded text-center">
                                <div className="icon-lg mb-3">
                                    <Icon path={mdiCertificate} size={1.5} />
                                </div>
                                <h4>Sertifikasyon</h4>
                                <p>Resmi belgelerin hazırlanması</p>
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center mt-5">
                        <div className="col-lg-6 mb-3">
                            <img src={raporImg} alt="Sertifikasyon" className="rounded w-100" />
                        </div>
                        <div className="col-lg-6 mb-3">
                            <div className="info-box">
                                <h3>Kalite ve Güvenlik</h3>
                                <p>LPG montaj işlemlerimizde en yüksek kalite ve güvenlik standartlarını uyguluyoruz:</p>
                                <ul className="list-check primary">
                                    <li>TSE ve ECE R67.01 standartlarına uygunluk</li>
                                    <li>Orijinal ve garantili parça kullanımı</li>
                                    <li>Detaylı test ve kontrol süreçleri</li>
                                    <li>Profesyonel montaj ve kalibrasyon</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <SavingsBot />

                    <div className="row mt-5">
                        <div className="col-lg-12">
                            <div className="bg-light p-4 rounded">
                                <h4 className="text-center mb-4">Montaj Randevusu Alın</h4>
                                <p className="text-center">
                                    Profesyonel LPG montaj hizmetimiz için hemen randevu alın. 
                                    <br />
                                    <strong>Telefon:</strong> <a href="tel:+902125641134">0212 564 11 34</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
}

export default Montaj; 