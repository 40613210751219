import React from 'react';
import { Link } from 'react-router-dom';

const PageTitle = ({motherMenu, activeMenu}) => {
    return (
        <div className="dlab-bnr-inr bg-primary" style={{backgroundImage: "url(" + require('../../images/banner/bnr1.jpg') + ")"}}>
            <div className="container">
                <div className="dlab-bnr-inr-entry">
                    <h1>{activeMenu}</h1>
                    <nav aria-label="breadcrumb" className="breadcrumb-row">
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">
                                    <i className="fas fa-home me-1"></i>
                                    <span>Ana Sayfa</span>
                                </Link>
                            </li>
                            {motherMenu && <li className="breadcrumb-item"><i className="fas fa-angle-right me-1"></i>{motherMenu}</li>}
                            <li className="breadcrumb-item active" aria-current="page"><i className="fas fa-angle-right me-1"></i>{activeMenu}</li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
}

export default PageTitle; 