import React, { useState, useEffect } from 'react';
import './IframeModal.css';

const IframeModal = ({ url, onClose, onSuccess }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data === 'REGISTRATION_SUCCESS') {
        onSuccess();
        // Close the modal after a short delay to show the success message
        setTimeout(() => {

          handleClose();
        }, 1000);
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    onClose && onClose();
  };

  return isVisible ? (
    <div className="iframe-modal-overlay">
      <div className="iframe-modal-container">
        <button 
          className="iframe-modal-close-btn" 
          onClick={handleClose}
          aria-label="Close modal"
        >
          ✕
        </button>
        <iframe 
          id='iframe-modal-content'
          src={url} 
          className="iframe-modal-content"
          title="External Content"
          frameBorder="0"
        />
      </div>
    </div>
  ) : null;
};

export default IframeModal; 